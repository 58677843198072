<h1 class="mb-4 mt-2 text-2xl font-bold">Create a New Release Note</h1>

<form [formGroup]="releaseNoteFormService.releaseNoteForm">
    <div class="mt-4 flex flex-wrap gap-2">
        <tui-input type="text" class="flex-1" formControlName="title"> Title </tui-input>

        <div class="flex-1">
            <tui-input-date
                class="w-full"
                placeholder="Release Date"
                formControlName="releaseDate"
                tuiUnfinishedValidator="Finish filling the field"
            >
                Release Date
            </tui-input-date>
            <tui-error
                formControlName="releaseDate"
                [error]="[] | tuiFieldError | async"
                class="text-error"
            ></tui-error>
        </div>
        <div class="flex-1">
            <tui-input type="text" formControlName="version"> Version No. </tui-input>
            @if (releaseNoteFormService.hasTextareaMinLengthError) {
                <small class="text-sm text-error">
                    New version must be at least 3 characters long...
                </small>
            }
        </div>
    </div>

    <div class="mt-2">
        <ngx-editor-menu
            [editor]="editor"
            [customMenuRef]="customMenu"
            [toolbar]="toolbar"
            class="sticky top-[68px] z-[5]"
            [ngClass]="{
                '[&>*]:!bg-slate-800 [&>*]:!text-white':
                    appStateService.select('isDarkMode') | async,
            }"
        ></ngx-editor-menu>

        <!-- Create template reference -->
        <ng-template #customMenu>
            <app-custom-menu [editor]="editor"></app-custom-menu>
        </ng-template>
        <ngx-editor
            class="!prose [&>*]:!min-h-[60vh] [&>*]:!w-full"
            [ngClass]="{
                '[&>*]:!bg-slate-800 [&>*]:!text-white':
                    appStateService.select('isDarkMode') | async,
            }"
            [editor]="editor"
            formControlName="content"
        >
            <ngx-editor-floating-menu [editor]="editor">
                <ngx-editor-menu [editor]="editor" [toolbar]="toolbar" [customMenuRef]="customMenu">
                </ngx-editor-menu>
            </ngx-editor-floating-menu>
        </ngx-editor>
    </div>

    <div>
        <button (click)="onSubmit()" tuiButton class="btn btn-primary mt-2 w-1/5">Submit</button>
    </div>
</form>
